// src/store.js
import { createStore } from 'vuex';

function hexToRgba(hex, alpha = 0.4) {
  // Remove the hash at the start if it's there
  hex = hex.replace(/^#/, '');

  // Parse the hex string
  let r, g, b;
  if (hex.length === 3) {
    // If it's a shorthand hex code (#f00 -> #ff0000)
    r = parseInt(hex[0] + hex[0], 16);
    g = parseInt(hex[1] + hex[1], 16);
    b = parseInt(hex[2] + hex[2], 16);
  } else if (hex.length === 6) {
    // If it's a full hex code (#ff0000)
    r = parseInt(hex.substring(0, 2), 16);
    g = parseInt(hex.substring(2, 4), 16);
    b = parseInt(hex.substring(4, 6), 16);
  } else {
    throw new Error('Invalid hex color');
  }

  // Return the rgba string
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}
const store = createStore({
  state: {
    // exam_id is stored here and synced with localStorage to persist across reloads
    exam_id: localStorage.getItem('exam_id') || null,
    user_id: localStorage.getItem('user_id') || null,
    // Chart data for the radar chart
    chartData: {
      labels: [
        'Central vision field',
        'Peripheral vision field',
        'Eye moisturization',
        'Color recognition',
        'Contrast sensitivity',
        'Color sensitivity',
      ],
      datasets: [
        {
          label: 'Vision Test Scores',
          fill: true,
          backgroundColor: 'rgba(75, 192, 192, 0.4)',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 2,
          data: [0, 0, 0, 0, 0, 0], // Placeholder scores, will be updated dynamically
        },
      ],
    },

    // Chart options for the radar chart
    chartOptions: {
      responsive: true,  // Make it responsive
      maintainAspectRatio: false,  // Allow it to resize dynamically
      plugins: {
        legend: {
          display: false,  // Disable the legend
        },
        title: {
          display: false,  // Disable the title
        },
        tooltip: {
          enabled: true,
          bodyFont: {
            size: 16,  // Increase font size for tooltips
          },
        },
      },
      scales: {
        r: {
          beginAtZero: true,
          max: 5,
          ticks: {
            beginAtZero: true,
            max: 5,  // Max score is 5
            min: 0,
            stepSize: 1,
            font: {
              size: 14,  // Increase font size for ticks
            },
          },
          pointLabels: {
            font: {
              size: 16,  // Increase font size for labels (axes)
            },
          },
        },
      },
    },
  },

  mutations: {
    // Set exam_id in Vuex and persist it to localStorage
    setExamId(state, examId) {
      state.exam_id = examId;
      localStorage.setItem('exam_id', examId); // Persist in localStorage
    },

    // Clear exam_id from Vuex and remove it from localStorage
    clearExamId(state) {
      state.exam_id = null;
      localStorage.removeItem('exam_id');
    },

    setUserId(state, userId) {
      state.user_id = userId;
      localStorage.setItem('user_id', userId); // Persist in localStorage
    },

    // Clear user_id from Vuex and remove it from localStorage
    clearUserId(state) {
      state.user_id = null;
      localStorage.removeItem('user_id');
    },

    // Update the test score for a specific field index
    updateTestScore(state, { fieldIndex, score }) {
      state.chartData.datasets[0].data[fieldIndex] = score;
    },

    // Increment the test score for a specific field index
    incrementTestScore(state, fieldIndex) {
      if (state.chartData.datasets[0].data[fieldIndex] !== undefined) {
        state.chartData.datasets[0].data[fieldIndex] += 1;
      }
    },

    // Update chart labels and data dynamically (useful if provided by the backend)
    updateChartData(state, { labels, data, color }) {
      // Convert hex color to rgba with transparency
      const backgroundColor = hexToRgba(color, 0.4);  // Here we pass the desired alpha (transparency)
      // Update the chart data, including color updates
      state.chartData = {
        ...state.chartData,
        labels: [...labels],
        datasets: [
          {
            ...state.chartData.datasets[0],
            data: [...data],
            backgroundColor: backgroundColor,  // Fill color with transparency
            borderColor: color,  // Line color
            pointBackgroundColor: color,  // Color of points on the chart
            pointBorderColor: '#fff',  // Border color of points
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: color,
          }
        ]
      };
    },
  },

  getters: {
    // Get the exam_id from the state
    getExamId(state) {
      return state.exam_id;
    },
    getUserId(state) {
      return state.user_id;
    },

    // Get chart data for the radar chart
    getChartData(state) {
      return state.chartData;
    },

    // Get chart options for the radar chart
    getChartOptions(state) {
      return state.chartOptions;
    },
  },

  actions: {
    // Action to set exam_id in Vuex and sync with localStorage
    setExamId({ commit }, examId) {
      commit('setExamId', examId);
    },

    // Action to clear exam_id from Vuex and localStorage
    clearExamId({ commit }) {
      commit('clearExamId');
    },

    setUserId({ commit }, userId) {
      commit('setUserId', userId);
    },

    // Action to clear exam_id from Vuex and localStorage
    clearUserId({ commit }) {
      commit('clearUserId');
    },

    // Action to update the test score for a specific field
    updateTestScore({ commit }, { fieldIndex, score }) {
      commit('updateTestScore', { fieldIndex, score });
    },

    // Action to increment the test score for a specific field
    incrementTestScore({ commit }, fieldIndex) {
      commit('incrementTestScore', fieldIndex);
    },

    // Action to update chart data dynamically (e.g., from a backend API)
    updateChartData({ commit }, { labels, data }) {
      commit('updateChartData', { labels, data });
    },
  },
});

export default store;